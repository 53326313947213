<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>{{ title }} Role Endpoint</h5>
          </div>
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="onSubmit" autocomplete="off">
              <div class="ibox-content">
                <div class="row">
                  <div class="form-group col-md-4">
                    <ValidationProvider name="Endpoint Name" :rules="'required'" v-slot="{ errors, classes }">
                      <label>Role Name *</label>
                       <select class="form-control apiTypeClass" v-model="roleEndpoint.roleName" :class="classes">
                        <option value="" selected>--------- Select ---------</option>
                        <option v-for="(type, key) in role" :value="type.id" v-bind:key="key">{{ type.text }}</option>
                      </select>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-md-4">
                    <label>URL (Path)*</label>
                    <ValidationProvider name="URL" :rules="{ required: true, url }" v-slot="{ errors, classes }">
                      <input type="text" placeholder="path" v-model="roleEndpoint.url" class="form-control" :class="classes" />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-4">
                    <ValidationProvider name="Config Key" :rules="'required'" v-slot="{ errors, classes }">
                      <label>Method *</label>
                      <select class="form-control apiTypeClass" v-model="roleEndpoint.method" :class="classes">
                        <option value="" selected>--------- Select ---------</option>
                        <option v-for="(type, key) in apiMethod" :value="type.id" v-bind:key="key">{{ type.text }}</option>
                      </select>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-4 col-sm-offset-2">
                    <button class="btn btn-white btn-sm" type="button">Clear</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button class="btn btn-primary btn-sm ladda-button" data-style="zoom-in" type="submit" :disabled="invalid">Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import Utils from "../plugin/Utils";

export default {
  data() {
    return {
      roleEndpoint: {
        id: 0,
        roleName: "",
        url: "",
        method: "",
      },
      title: Constant.data.ADD_TITLE,
      url: Constant.url.ROLE_ENDPOINT_ADD,
      apiMethod: {},
      role: [{"id":"RenAdmin","text":"RenAdmin"}]
    };
  },
  created: function () {
    this.loadEndpointData();
    this.loadMethod();
  },
  mounted: function () {
    const self = this;
    $("#urlVersion").change(function () {
      self.roleEndpoint.urlVersion = $(this).is(":checked");
    });
    $("#apiProvider").change(function () {
      self.roleEndpoint.apiProvider = $(this).is(":checked");
    });
    $("#customerUrl").change(function () {
      self.roleEndpoint.customerUrl = $(this).is(":checked");
    });
  },
  methods: {
    onSubmit: function () {
      const self = this;
      AxiosService.post(this.url, this.roleEndpoint).then((result) => {
        if (Utils.hasValue(result, Constant.data.RESPONSE_CODE) && result.responseCode == Constant.data.SUCCESS) {
          self.$router.replace(Constant.url.SEPARATER + Constant.url.ROLE_ENDPOINT_URL);
        }
      });
    },
    loadEndpointData: function () {
      const self = this;
      var endpointId = this.$route.query.id;
      if (endpointId != undefined) {
        AxiosService.get(Constant.url.ROLE_ENDPOINT_GET + endpointId).then((result) => {
          self.roleEndpoint = result;
        });
        this.title = Constant.data.UPDATE_TITLE;
        this.url = Constant.url.ROLE_ENDPOINT_UPDATE;
      }
    },
    loadMethod: function () {
      const self = this;
      AxiosService.get(Constant.url.API_DEFINITION_METHOD_KEY_VALUE).then((result) => {
        self.apiMethod = result;
      });
    },
  },
};
</script>
